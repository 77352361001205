.App {
  text-align: center;
  background-color: black;
  color: white;
  font-family: monospace;
}

body {
  --background-color: black;
  --bs-body-bg: black;
}

table {
  margin: 0;
  padding: 0px;
  border-collapse:collapse;
  border:0px;
}

table td {
  border-left: 5px solid white;
  border-right: 5px solid white;
  padding: 0px;
  margin: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Timezone {
 font-size: 64px;
}

.clock {
  font-size: 36px;
  font-weight: 700;
}

.court-title {
  font-size: 36px;
  font-weight: 900;
}


.titles {
  font-size: 48px;
  font-weight: 900;
}

.details-normal {
  font-size: 28px;
  font-weight: 900;
}

.details-large {
  font-size: 64px;
  font-weight: 900;
}
.nownext {
  font-size: 32px;
}
